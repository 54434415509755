import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SliderSectionContiner, ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A000,
  position: 'relative',
  padding: theme.spacing(15, 0),
  textAlign: 'center',
  color: theme.palette.common.black,

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(8, 0, 4.75, 0),
  },
  '& .TSSectionBackground': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme.spacing(-2),
    width: '100%',
    height: '50%',
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  '& .TSTitle': {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1),
      fontSize: theme.spacing(1.75),
    },
  },
  '& .TSSubTitle': {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
      fontSize: theme.spacing(2.5),
    },
  },
  '& .TStriangle': {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: `${theme.spacing(2)} solid transparent`,
    borderRight: `${theme.spacing(2)} solid transparent`,
    borderTop: `${theme.spacing(2)} solid ${theme.palette.common.white}`,
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'border-color 0.5s ease',
  },
  '& .TSCommentIcon': {
    margin: ` 0 auto ${theme.spacing(2)}`,
    display: 'block',
    '& path': {
      transition: 'fill 0.5s ease',
    },
  },
  '& .TSCommentTitle': {
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(2),
    transition: 'color 0.5s ease',
  },
  '& .TSCommentDesc': {
    color: theme.palette.grey[400],
    transition: 'color 0.5s ease',
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },
  '& .TSTestimonialsCard': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .TSTestimonialsText': {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4, 3),
    position: 'relative',
    marginBottom: theme.spacing(5),
    transition: 'background-color 0.5s ease',
    flex: ' 1 0 0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .TSCommentIcon': {
        '& path': {
          fill: theme.palette.common.black,
        },
      },
      '& .TSCommentTitle': {
        color: theme.palette.common.black,
      },
      '& .TSCommentDesc': {
        color: theme.palette.common.black,
      },
      '& .TStriangle': {
        borderTop: `${theme.spacing(2)} solid ${theme.palette.primary.main}`,
      },
    },
  },
  '& .TSUserImg': {
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: 'auto',
  },
  '& .TSUserName': {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
  },
}));
export const StyledSliderSectionContiner = styled(SliderSectionContiner)(
  ({ theme }) => ({
    '& .CarouselItem': {
      height: '100%',
    },
    '& .SDContiner': {
      marginTop: theme.spacing(1),
    },
    '& .SDSlideItem': {
      cursor: 'pointer',
      width: `${theme.spacing(1.25)}!important`,
      height: `${theme.spacing(1.25)}!important`,
      border: '0 !important',
      backgroundColor: `${theme.palette.grey[100]}!important`,

      '&.active': {
        backgroundColor: `${theme.palette.secondary.light}!important`,
      },
    },
  }),
);
export const StyledImgBox = styled(ImgBox)(() => ({
  ' & .IBimg': {
    objectPosition: 'top',
  },
}));
export default StyledBox;
