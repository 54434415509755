import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SliderSectionContiner } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A000,
  position: 'relative',
  padding: theme.spacing(14, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6, 0),
  },
  '& .ESContent': {
    position: 'relative',
    zIndex: 2,
  },
  '& .ETSImgGrid': {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(3),
    },
  },
  '& .ETSImgesBackground': {
    width: '93%',
    height: theme.spacing(56.25),
    position: 'absolute',
    left: theme.spacing(7),
    top: theme.spacing(4),
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  '& .ETSImgGallery': {
    height: theme.typography.pxToRem(450),
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(350),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(250),
    },
  },

  '& .ETSDesc': {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },
  '& .ETSLTInfo': {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
    },
  },
  '& .ETSLTIcon': {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  '& .ETSLTText': {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  '& .ETSSectionBackground': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme.spacing(-8),
    bottom: 0,
    width: '100%',
    height: '100%',
    // zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      top: theme.spacing(2.25),
      bottom: 'unset',
      height: theme.spacing(15.5),
    },
  },
}));

export const StyledSliderSectionContiner = styled(SliderSectionContiner)(
  ({ theme }) => ({
    '& .chirldrenWrapper': {
      marginTop: 0,
    },
    '& .SDSlideItem': {
      cursor: 'pointer',
      width: `${theme.spacing(1.25)}!important`,
      height: `${theme.spacing(1.25)}!important`,
      border: '0 !important',
      backgroundColor: `${theme.palette.grey[100]}!important`,

      '&.active': {
        backgroundColor: `${theme.palette.secondary.light}!important`,
      },
    },
    '& .CarouselItem': {
      margin: '0 !important',
    },
    '& .react-multiple-carousel__arrow': {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
      width: `${theme.spacing(5)}!important`,
      height: `${theme.spacing(5)}!important`,
      minHeight: 'unset !important',
      minWidth: 'unset !important',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.9),
      '&.react-multiple-carousel__arrow--right': {
        right: `${theme.spacing(2.75)}!important`,
      },
      '&.react-multiple-carousel__arrow--left': {
        left: `${theme.spacing(2.75)}!important`,
      },
      '&:before': {
        fontWeight: 'bold',
        fontSize: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  }),
);
export default StyledBox;
