import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(3),
  },
  '& .BBStartText': {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  '& .BBStartPrice': {
    color: theme.palette.secondary.main,
  },
  '&.BBNowFixed': {
    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      zIndex: '2',
      padding: theme.spacing(1.5, 1.5),
      backgroundColor: theme.palette.common.white,
      borderTop: `2px solid ${theme.palette.grey['100']}`,
    },
  },
}));
export default StyledBox;
