import React, { useEffect, useState, useRef } from 'react';
import StyledBox from 'views/OffRoadToursLandingView/components/BookingBtn/BookingBtn.styles';
import { useTranslation } from 'next-i18next';
import { Box, Typography } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import OffRoadButton from 'views/OffRoadToursLandingView/components/OffRoadBtn.styles';

const BookingBtn = (props) => {
  const { startPrice, btnPath } = props;
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');
  const [isMobileActionsFixed, setMobileActionsFixed] = useState(false);

  const mobileActionsHolderRef = useRef(null);
  useEffect(() => {
    if (mobileActionsHolderRef && process.browser) {
      window.addEventListener('scroll', () => {
        if (mobileActionsHolderRef.current) {
          const mobileActionsHolderRefOffsetTop = mobileActionsHolderRef.current.offsetTop;
          if (window.scrollY - 500 > mobileActionsHolderRefOffsetTop) {
            setMobileActionsFixed(true);
          } else {
            setMobileActionsFixed(false);
          }
        }
      });
    }
  }, [mobileActionsHolderRef]);

  return (
    <StyledBox
      className={`${isMobileActionsFixed ? 'BBNowFixed' : ''}`}
      ref={mobileActionsHolderRef}>
      <Box>
        <Typography component="p" variant="caption" className="BBStartText">
          {t('fe_er_off_road_tours_landing_page:starting_at')}
        </Typography>
        <Typography500 component="p" variant="h3" className="BBStartPrice">
          {startPrice}
        </Typography500>
      </Box>
      <OffRoadButton
        variant="contained"
        component={NavigatorLink}
        href={btnPath}>
        {t('fe_er_off_road_tours_landing_page:book_your_tour')}
      </OffRoadButton>
    </StyledBox>
  );
};

export { BookingBtn };
