import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: theme.typography.pxToRem(530),
  [theme.breakpoints.down('lg')]: {
    height: theme.typography.pxToRem(281),
  },
  '& .SSImgContainer': {
    position: 'absolute',
    zIndex: -1,
    left: 0,
    right: 0,
    top: 0,
    Bottom: 0,
    height: theme.typography.pxToRem(530),
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(281),
    },
  },
  '& .SSCaptionContainer': {
    height: '100%',
  },
  '& .SSCaption': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    justifyContent: 'center',
    textAlign: 'end',
  },
  '& .SSCaptionText': {
    fontWeight: 1000,
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('lg')]: {
      lineHeight: theme.typography.pxToRem(32),
    },
  },
  '& .SSCaptionAlways': {
    fontSize: theme.typography.pxToRem(63),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(33),
    },
  },
  '& .SSCaptionGoing': {
    fontSize: theme.typography.pxToRem(80),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(44),
    },
  },
  '& .SSCaptionOff': {
    fontSize: theme.typography.pxToRem(141),
    lineHeight: theme.typography.pxToRem(125),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(80),
      lineHeight: theme.typography.pxToRem(70),
    },
  },
}));

export default StyledBox;
