import { Box, Dialog, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A000,
  padding: theme.spacing(0, 0, 3, 0),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(8, 0),
  },
  '& .BSBenfitGrid': {
    [theme.breakpoints.down('lg')]: {
      order: 2,
      paddingBottom: theme.spacing(3),
    },
  },
  '& .BSBenfitContainer': {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 0),
    },
  },
  '& .BSSubTitle': {
    color: theme.palette.common.black,
  },
  '& .BSBenfit': {
    marginTop: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
  },
  '& .BSBenfitIcon': {
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.spacing(4),
    },
  },
  '& .BSBenfitText': {
    color: theme.palette.common.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },
  '& .BSGalleryGrid': {
    [theme.breakpoints.down('lg')]: {
      '&:nth-last-child(-n+2)': {
        display: 'none',
      },
    },
  },
  '& .BSBenfitImgContainer': {
    cursor: 'pointer',
    overflow: 'hidden',
    height: theme.typography.pxToRem(268),
    [theme.breakpoints.down('xl')]: {
      height: theme.typography.pxToRem(250),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(150),
    },
    '&:hover': {
      '& .IBimgbox': {
        transform: 'scale(1.05)',
        transition: 'transform 0.4s linear',
      },
    },
  },
}));
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    '& ul': {
      '& img': {
        height: 'auto',
        width: '100%',
        objectFit: 'contain',
      },
    },
  },
  '& .MuiDialog-paperFullScreen': {
    backgroundColor: 'transparent',
  },
  '& .closeBtn': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 9,
    backgroundColor: alpha(theme.palette.secondary.main, 0.5),
    color: theme.palette.common.fogWhite,
  },
}));
export default StyledBox;
