import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  height: theme.spacing(100),
  width: '100%',
  position: 'relative',
  overflow: 'hidden',

  [theme.breakpoints.down('lg')]: {
    height: 'auto',
    backgroundColor: theme.palette.common.white,
  },
  '& .HSVideo': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      top: 0,
      right: 0,
      left: 0,
      transform: 'unset',
      height: theme.typography.pxToRem(250),
      objectFit: 'cover',
      zIndex: 1,
    },
  },
  '& .HSContentContainer': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      position: 'unset',
    },
  },
  '& .HSContent': {
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.common.black,
      padding: theme.spacing(3, 0),
    },
  },
  '& .HSTitle': {
    marginBottom: theme.spacing(3),
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(4.5),
      textShadow: 'unset',
    },
  },
  '& .HSSubTitle': {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
    },
  },
  '& .ORTLVBtn': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  '& .HSPhone': {
    position: 'absolute',
    right: 0,
    top: theme.spacing(4),
    height: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      right: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2),
    },
  },
  '& .HSPhoneContainer': {
    position: 'relative',
    '&:hover': {
      '& .HSPhoneLable': {
        display: 'inline-flex',
      },
    },
  },
  '& .HSPhoneBtn': {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  '& .HSPhoneLable': {
    height: theme.spacing(5),
    borderRadius: theme.spacing(3.75),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 6.375, 0, 2), // padding right width of icon + space between icon and text
    color: theme.palette.secondary.main,
    display: 'inline-flex',
    alignItems: 'center',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    textDecoration: 'none',
  },
}));

export default StyledBox;
