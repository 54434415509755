import React from 'react';
import StyledBox from 'views/OffRoadToursLandingView/components/ServicesSection/ServicesSection.styles';
import { Box, Container, Typography } from '@mui/material';
import { ImgBox } from 'views/common/components';
import { useTranslation } from 'next-i18next';

const ServicesSection = () => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');

  return (
    <StyledBox>
      <Container maxWidth="xl" className="SSCaptionContainer">
        <Box className="SSCaption">
          <Typography
            component="p"
            variant="h1"
            className="SSCaptionText SSCaptionAlways">
            {t('fe_er_off_road_tours_landing_page:always')}
          </Typography>
          <Typography
            component="p"
            variant="h1"
            className="SSCaptionText SSCaptionGoing">
            {t('fe_er_off_road_tours_landing_page:going')}
          </Typography>
          <Typography
            component="p"
            variant="h1"
            className="SSCaptionText SSCaptionOff">
            {t('fe_er_off_road_tours_landing_page:off')}
          </Typography>
        </Box>
      </Container>
      <Box className="showDESKTOP SSImgContainer">
        <ImgBox
          image={{
            src: '/imgs/off-road/ServicesLG.png',
            alt: 'off-road image',
          }}
          imgHeight="100%"
          imgWidth="100%"
          imgObjectFit="cover"
        />
      </Box>
      <Box className="showTABLET SSImgContainer">
        <ImgBox
          image={{
            src: '/imgs/off-road/ServicesMD.png',
            alt: 'off-road image',
          }}
          imgHeight="100%"
          imgWidth="100%"
          imgObjectFit="cover"
        />
      </Box>
    </StyledBox>
  );
};
export { ServicesSection };
