import React, { useState } from 'react';
import StyledBox, {
  StyledDialog,
} from 'views/OffRoadToursLandingView/components/BenefitsSection/BenefitsSection.styles';
import { useTranslation } from 'next-i18next';
import {
  Box, Container, IconButton, Grid, Typography,
} from '@mui/material';
import { ImgBox } from 'views/common/components';
import HeroGallery from 'views/common/components/UI/HeroGallery';
import CloseIcon from '@mui/icons-material/Close';
import Typography500 from 'views/common/components/UI/Typography500';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import OffRoadTitle from 'views/OffRoadToursLandingView/components/OffRoadTitle.styles';

const BenefitsSection = () => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');

  const toursImg = [
    {
      id: 1,
      urls: {
        large: '/imgs/off-road/benefitsLG1.jpg',
        small: '/imgs/off-road/benefitsSM1.png',
        alt: 'tour-img',
      },
    },
    {
      id: 2,
      urls: {
        large: '/imgs/off-road/benefitsLG2.jpg',
        small: '/imgs/off-road/benefitsSM2.png',
        alt: 'tour-img',
      },
    },
    {
      id: 3,
      urls: {
        large: '/imgs/off-road/benefitsLG3.jpg',
        small: '/imgs/off-road/benefitsSM3.png',
        alt: 'tour-img',
      },
    },
    {
      id: 4,
      urls: {
        large: '/imgs/off-road/benefitsLG4.jpg',
        small: '/imgs/off-road/benefitsSM4.png',
        alt: 'tour-img',
      },
    },
    {
      id: 5,
      urls: {
        large: '/imgs/off-road/benefitsLG5.jpg',
        small: '/imgs/off-road/benefitsSM5.png',
        alt: 'tour-img',
      },
    },
    {
      id: 6,
      urls: {
        large: '/imgs/off-road/benefitsLG6.jpg',
        small: '/imgs/off-road/benefitsSM6.png',
        alt: 'tour-img',
      },
    },
  ];
  const benefits = [
    {
      id: 1,
      text: t('fe_er_off_road_tours_landing_page:benfit_1'),
    },
    {
      id: 2,
      text: t('fe_er_off_road_tours_landing_page:benfit_2'),
    },
    {
      id: 3,
      text: t('fe_er_off_road_tours_landing_page:benfit_3'),
    },
    {
      id: 4,
      text: t('fe_er_off_road_tours_landing_page:benfit_4'),
    },
    {
      id: 5,
      text: t('fe_er_off_road_tours_landing_page:benfit_5'),
    },
  ];
  const [openGallery, setOpenGallery] = useState(false);

  const handleGalleryOpen = () => {
    setOpenGallery(true);
  };

  const handleGalleryClose = () => {
    setOpenGallery(false);
  };
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Grid container columnSpacing={{ lg: 4, md: 0 }} rowSpacing={3}>
          <Grid item xs={12} md={12} lg={6} className="BSBenfitGrid">
            <Box className="BSBenfitContainer">
              <OffRoadTitle component="h2" variant="h2">
                {t('fe_er_off_road_tours_landing_page:rugged_adventure')}
              </OffRoadTitle>
              <Typography500 component="p" variant="h4" className="BSSubTitle">
                {t('fe_er_off_road_tours_landing_page:eagleRider_off_road_way')}
              </Typography500>
              {benefits.map((benfit) => (
                <Box key={benfit.id} className="BSBenfit">
                  <CheckBoxOutlinedIcon
                    color="secondary"
                    className="BSBenfitIcon"
                  />
                  <Typography
                    className="BSBenfitText"
                    component="p"
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: benfit.text,
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid container>
              {toursImg.map((img) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  key={img.id}
                  className="BSGalleryGrid">
                  <Box
                    onClick={handleGalleryOpen}
                    className="BSBenfitImgContainer">
                    <ImgBox
                      image={{
                        src: img.urls.small,
                        alt: img.alt,
                      }}
                      imgHeight="100%"
                      imgWidth="100%"
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <StyledDialog open={openGallery} onClose={handleGalleryClose} fullScreen>
        <IconButton
          onClick={handleGalleryClose}
          color="secondary"
          className="closeBtn"
          size="large">
          <CloseIcon />
        </IconButton>
        <HeroGallery
          slidesArray={toursImg}
          fullScreen
          videoWidth="640px"
          videoHeight="480px"
          shouldResetAutoplay={false}
          sliderDots={false}
        />
      </StyledDialog>
    </StyledBox>
  );
};

export { BenefitsSection };
